import React, { useState } from "react";
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout/Layout'
import PreviewCompatibleImage from "../components/image/PreviewCompatibleImage";
import { useIsMobile } from "../hooks/useIsMobile";


const imageInfo1 = {
  "alt": "Un groupe ayant réalisé un enterrement de vie de jeune fille pose avec leurs réalisations",
  "image": "/img/v2/Pvosevenements-min.JPG",
  "maxWidth": 250
}

const imageInfo2 = {
  "alt": "Un buffet proposé lors d'un évenement a l'atelier forma.",
  "image": "/img/v2/Pvosevenements2-min.jpg",
  "maxWidth": 250
}

export const VosEvenementsTemplate = ({imageEvenement}) => {
  const {isMobile} = useIsMobile()

  return (
    <div className="content">
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-12">
              <div className="columns has-text-justified">

                <div className="column is-6">
                  <h3 className="title is-4">Notre lieu pourrait aussi devenir le votre le temps d'un évènement ! </h3>

                  <p>Réunion d'équipe, enterrement de vie de jeune fille, anniversaire, baby shower sont des occasions toutes destinées à l'atelier forma. </p>

                  <p>Contactez-nous pour établir un devis en fonction de vos envies... Nous nous ferons un plaisir de composer avec vous une offre sur-mesure. </p>
                  <p>L'activité de peinture sur céramique ou de découverte du modelage sont idéales pour passer un moment original, convivial et créatif ! </p>
                  <p>Notre cuisine nous permet de vous proposer d'accompagner ce moment d'un repas, goûter, petit-déjeuner, apéro, brunch, buffet, boissons...</p>
                  <p></p>
                </div>

                <div className="column has-text-centered is-3 is-justify-content-center is-flex">
                  <PreviewCompatibleImage imageInfo={imageInfo2} />
                </div>

                <div className="column has-text-centered is-3 is-justify-content-center is-flex">
                  <PreviewCompatibleImage imageInfo={imageInfo1} />
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )}

VosEvenementsTemplate.propTypes = {
  imageEvenement: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

}

const VosEvenements = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <VosEvenementsTemplate
        imageEvenement={frontmatter.imageEvenement}
      />
    </Layout>
  )
}

VosEvenements.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default VosEvenements

export const pageQuery = graphql`
  query VosEvenementsTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "vos-evenements" } }) {
      frontmatter {
        title
        imageEvenement {
          alt
          image {
            childImageSharp {
                gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
            }
          }
          maxWidth
        }
      }
    }
  }
`
